import { DatePicker } from '@progress/kendo-react-dateinputs'
import { SearchAccount } from 'components/common/search-accounts/component'
import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import * as Flash from 'components/flash'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { parseISO } from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { validateEmail, validateDomain} from 'generic/utility'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import * as React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Modals from 'shared/modals'
import { BillingPlanCode, CurrentPlan } from 'shared_server_client/types/billing_plan'
import * as Errors from 'shared_server_client/types/error'
import { RasaBillingPlans } from './constants'
import { Dataset } from 'generic/dataset'
import { DEFAULT_PARTNER_IDENTIFIER } from 'shared_server_client/constants'

interface AddNewsletterState extends Modals.ModalComponentState {
  currentPlan?: CurrentPlan,
  name: string,
  isSuperUser: boolean,
  slectedAccountId: number,
}

interface AddNewsletterProps extends Modals.ModalComponentProps {
  push: any,
}

export class AddNewsletterModalComponent extends Modals.GenericModalComponent<AddNewsletterProps, AddNewsletterState> {
  public static contextType = RasaContext
  public static key: string = 'addNewsletter'
  public static defaultProps = {
    closeButtonText: 'Close',
    saveText: 'Just Create It',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Add a newsletter',
  }
  private sharedStore: SharedStore

  constructor(props: AddNewsletterProps) {
    super(props, AddNewsletterModalComponent.key, 'Add a newsletter')
    this.state = {
      name: '',
      isSaving: false,
      isSuperUser: false,
      slectedAccountId: 0,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, role]) => {
      this.setState({
        currentPlan: activeCommunity.billingInfo.currentPlan,
        isSuperUser: role === Roles.super_admin,
        slectedAccountId: 0,
      }, () => {
        if (this.state.isSuperUser) {
          AddNewsletterModalComponent.defaultProps.secondAction = null
        }
      })
    })
  }

  protected renderChildren(data: any) {
    return (
      <div className="add-newsletter-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            <h4 className="label">Name your newsletter</h4>
            <input
              type="text"
              value={this.state.name}
              placeholder="Enter newsletter name..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({ name: e.target.value })
              }}
            />
            {this.state.isSuperUser &&
              <SearchAccount onAccountSelect = {this.handleAccountSelectCallback}/>
            }
          </div>
        }
      </div>
    )
  }

  protected handleAccountSelectCallback = (accountData) => {
    this.setState({slectedAccountId: accountData.id})
  }

  protected xButtonClick(data: any) {
    this.setState({
      slectedAccountId: null,
      name: null,
    })
    this.props.closeModal(AddNewsletterModalComponent.key)
  }

  protected saveDisabled(data: any): boolean {
    if (!this.state.name) {
      return true
    }
    if (this.state.isSuperUser && !(this.state.slectedAccountId > 0)) {
      return true
    }
    return false
  }

  protected doSave(data: any) {
    if (this.state.isSuperUser) {
      if (this.state.slectedAccountId > 0) {
        const url: string = AjaxWrapper.getServerUrl() + '/signup/admin/newsletter'
        const payload = {
          accountId: this.state.slectedAccountId,
          communityName: this.state.name,
          ipPool: this.state.currentPlan.name === BillingPlanCode.ZOHO_PRO_2023 ? 'medium' : 'low',
        }
        return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
          .then((response) => {
            this.switchCommunity(response)
        })
      }
    } else {
      const url: string = AjaxWrapper.getServerUrl() + '/signup/newsletter'
      const payload = {
        communityName: this.state.name,
      }
      return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((response) => {
        this.switchCommunity(response)
      })
    }
  }

  private switchCommunity = (communityIdentifier: string) => {
    this.context.user.switchCommunity(communityIdentifier).then(() => {
      return this.props.push('/')
    })
  }
}

export const AddNewsletterModal = connect(
  null,
  {
    push: Router.push,
  },
)(AddNewsletterModalComponent)

interface ExpirationModalState extends Modals.ModalComponentState {
  expiration_date?: Date,
}

interface ExpirationModalProps extends Modals.ModalComponentProps {
  expiration_date?: Date,
  onSave: (expirationDate: Date) => Promise<any>
}

export class ExpirationModalComponent extends Modals.GenericModalComponent<ExpirationModalProps, ExpirationModalState> {
  public static key: string = 'updateExpirationDate'
  constructor(props: ExpirationModalProps) {
    super(props, ExpirationModalComponent.key, 'Update Trial Expiration Date')
    this.state = {
      expiration_date: props.expiration_date,
      isSaving: false,
    }
  }

  public componentWillReceiveProps(nextProps) {
    // reset the timer if children are changed
    if (nextProps.expiration_date && nextProps.expiration_date !== this.props.expiration_date) {
      this.setState({expiration_date: parseISO(nextProps.expiration_date)});
    }
  }

  protected renderChildren(data: any) {
    return (
      <div className="expiration-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            <h4>Expiration Date</h4>
            <DatePicker className="schedule-date-picker"
              format="yyyy-MM-dd"
              value={this.state.expiration_date}
              onChange={(e) => this.setState({expiration_date: e.value})} ></DatePicker>
          </div>
        }
      </div>
    )
  }

  protected doSave(data: any) {
    return this.props.onSave(this.state.expiration_date).then(() => {
      this.setState({
        isSaving: false,
        expiration_date: null,
      })
    });
  }

}

export const ExpirationModal = connect(
  null,
  {
    push: Router.push,
  },
)(ExpirationModalComponent)

//Add Account Modal
interface AddAccountState extends Modals.ModalComponentState {
  accountName: string,
  address: string,
  city: string,
  communityId: string,
  state: string,
  country: string,
  postCode: string,
  newsletterName: string,
  emailAddress: string,
  firstname: string,
  isSuperUser: boolean,
  lastName: string,
  website: string,
  partnerIdentifier: any,
  partners: DropdownOption[],
  selectedPartner: DropdownOption,
  selectedRole: DropdownOption,
  roles: DropdownOption[],
  plans: DropdownOption[],
  plan: DropdownOption,
}

interface AddAccountProps extends Modals.ModalComponentProps {
  push: any,
}

const initialAccountModalState = {
  accountName: '',
  address: '',
  city: '',
  state: '',
  country: '',
  communityId: '',
  postCode: '',
  newsletterName: '',
  emailAddress: '',
  firstname: '',
  lastName: '',
  website: '',
  isSaving: false,
  isSuperUser: false,
  partnerIdentifier: DEFAULT_PARTNER_IDENTIFIER,
  selectedPartner: null,
  selectedRole: null,
}

export class AddAccountModalComponent extends Modals.GenericModalComponent<AddAccountProps, AddAccountState> {
  public static contextType = RasaContext
  public static key: string = 'addAccount'
  public static EMAIL_ALREADY_EXISTS: string = 'The provided email address already exists for a different account and cannot be added to this one.'
  private sharedStore: SharedStore
  public static defaultProps = {
    closeButtonText: 'Close',
    saveText: 'Save',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Add an account',
  }
  constructor(props: AddAccountProps) {
    super(props, AddAccountModalComponent.key, 'Add an account')
    this.state = {
      ...initialAccountModalState,
      roles: [],
      partners: [],
      plans: [],
      plan: RasaBillingPlans[0],
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, role]) => {
      this.setState({
        isSuperUser: role === Roles.super_admin,
        communityId: activeCommunity.communityId,
        partnerIdentifier: activeCommunity.billingInfo.productSubscription.account_partner_identifier,
      }, () => {
        this.loadBillingPlans()
        this.loadPartners()
        this.loadRoles()
      })
    })
  }

  protected renderChildren(data: any) {
    return (
      <div className="add-account-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            {this.state.isSuperUser &&
            <div>
              <h4 className="label">Partner</h4>
              <div className='rasa-partner'>
                <DropdownComponent data={this.state.partners}
                  selected={this.state.partnerIdentifier}
                  onChange={(e: OnChangeEvent) =>
                    this.setState({
                      partnerIdentifier: e.selected.key,
                      selectedPartner: e.selected,
                    }, () => {
                      this.loadBillingPlans()
                    })
                  }
                />
              </div>
              {this.state.selectedPartner && this.state.selectedPartner.key !== DEFAULT_PARTNER_IDENTIFIER ?
              <div>
                <h4 className="label">Account Type</h4>
                <div className='rasa-role'>
                  <DropdownComponent data={this.state.roles}
                    selected={this.state.selectedRole ? this.state.selectedRole.key : ""}
                    onChange={(e: OnChangeEvent) =>
                      this.setState({
                        selectedRole: e.selected,
                      })
                    }
                  />
                </div>
              </div>: null}
            </div>}
            <h4 className="label">Account Name</h4>
            <input
              className={this.state.accountName ? '' : 'required'}
              type="text"
              value={this.state.accountName}
              placeholder="Enter account name (required)"
              onChange={(e) => {
                e.preventDefault()
                this.setState({ accountName: e.target.value })
              }}
            />
            <h4 className="label">Account Website</h4>
            <input
              className={validateDomain(this.state.website) ? '' : 'required'}
              type="text"
              value={this.state.website}
              placeholder="Enter account website (required)"
              onChange={(e) => {
                e.preventDefault()
                this.setState({ website: e.target.value })
              }}
            />
            <h4 className="label">Name of the Newsletter</h4>
            <input
              className={this.state.newsletterName ? '' : 'required'}
              type="text"
              value={this.state.newsletterName}
              placeholder="Enter Newsletter name (required)"
              onChange={(e) => {
                e.preventDefault()
                this.setState({ newsletterName: e.target.value })
              }}
            />
            <h4 className="label">Email address</h4>
            <input
              className={validateEmail(this.state.emailAddress) ? '' : 'required'}
              type="text"
              value={this.state.emailAddress}
              placeholder="Enter email address of the account owner (required)"
              onChange={(e) => {
                e.preventDefault()
                this.setState({ emailAddress: e.target.value })
              }}
            />
            <h4 className="label">First Name</h4>
            <input
              className={this.state.firstname ? '' : 'required'}
              type="text"
              value={this.state.firstname}
              placeholder="Enter first name of the account owner (required)"
              onChange={(e) => {
                e.preventDefault()
                this.setState({ firstname: e.target.value })
              }}
            />
            <h4 className="label">Last Name</h4>
            <input
              className={this.state.lastName ? '' : 'required'}
              type="text"
              value={this.state.lastName}
              placeholder="Enter last name of the account owner (required)"
              onChange={(e) => {
                e.preventDefault()
                this.setState({ lastName: e.target.value })
              }}
            />
            <h4 className="label">Address</h4>
            <input
              type="text"
              value={this.state.address}
              placeholder="Enter address..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({address: e.target.value})
              }}
            />
            <h4 className="label">City</h4>
            <input
              type="text"
              value={this.state.city}
              placeholder="Enter city name..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({city: e.target.value})
              }}
            />
            <h4 className="label">State</h4>
            <input
              type="text"
              value={this.state.state}
              placeholder="Enter state name..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({state: e.target.value})
              }}
            />
            <h4 className="label">Country</h4>
            <input
              type="text"
              value={this.state.country}
              placeholder="Enter country name..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({country: e.target.value})
              }}
            />
            <h4 className="label">Zip Code</h4>
            <input
              type="text"
              value={this.state.postCode}
              placeholder="Enter zip code..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({postCode: e.target.value})
              }}
            />
            {this.state.plans.length > 0 &&
            <div>
              <h4 className="label">Plan</h4>
              <div className='rasa-plan'>
                <DropdownComponent data={this.state.plans}
                                  selected={this.state.plan.key}
                                  onChange={(e: OnChangeEvent) =>
                                    this.setState({
                                      plan: e.selected,
                                    })
                                  }
                />
              </div>
            </div>}
          </div>
        }
      </div>
    )
  }
  protected onModalClose(data: any): void {
    this.setState({...initialAccountModalState})
    this.props.closeModal(AddAccountModalComponent.key)
  }

  protected close = () => {
    return this.props.closeModal(AddAccountModalComponent.key)
  }

  protected saveDisabled(data: any): boolean {
    if ( isEmpty(this.state.accountName) ||
         isEmpty(this.state.newsletterName) ||
         isEmpty(this.state.firstname) ||
         !validateDomain(this.state.website) ||
         !validateEmail(this.state.emailAddress)
    ) {
      return true
    }
    return false
  }

  protected doSave(data: any) {
    const url: string = AjaxWrapper.getServerUrl() + '/signup/admin/account'
    const payload = {
      account: {
        email: this.state.emailAddress,
        firstName: this.state.firstname,
        lastName: this.state.lastName,
        billingPlanCode: this.state.plan.key,
        partnerIdentifier: this.state.selectedPartner.key,
        role: this.state.selectedRole.key,
      },
      communityName: this.state.newsletterName,
      company: {
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        name: this.state.accountName,
        postCode: this.state.postCode,
        state: this.state.state,
        website: this.state.website,
      },
    }
    return AjaxWrapper.ajax(url, HttpMethod.POST, payload)
      .then((response) => {
        this.props.closeModal(AddAccountModalComponent.key)
        this.switchCommunity(response)
      }).catch((response) => {
        this.setState({isSaving: false})
        this.context.store.dispatch(Flash.showFlashError(
          Errors.getErrorMessage(get(response, 'response.message')) || AddAccountModalComponent.EMAIL_ALREADY_EXISTS))
      })
  }

  private switchCommunity = (communityIdentifier: string) => {
    this.context.user.switchCommunity(communityIdentifier).then(() => {
      return this.props.push('/')
    })
  }
  private loadBillingPlans = () => {
    new Dataset().loadCommunityDataset('billingPlansForCreateAccount', this.state.communityId,
      [{param: 'partnerIdentifier', value: this.state.partnerIdentifier}]
    )
    .then((result) => {
      const plans = result[0].map((plan) => {
        return {
          key: plan.code_monthly,
          value: plan.code_monthly,
          description: plan.name,
        }
      })
      this.setState({
        plans,
        plan: plans[0],
      }) 
    })
  }
  private loadPartners = () => {
    new Dataset().loadCommunityDataset('partners', this.state.communityId)
    .then((result) => {
      const partners = result[0].map((partner) => {
        return {
          key: partner.identifier,
          value: partner.identifier,
          description: partner.name,
        }
      })
      this.setState({
        partners,
        selectedPartner: partners[0],
      }) 
    })
  }
  private loadRoles = () => {
    Promise.resolve([{
        key: Roles.community_admin,
        value: Roles.community_admin,
        description: 'Normal',
      }, {
        key: Roles.partner_admin,
        value: Roles.partner_admin,
        description: 'Super User',
      }]
    ).then((roles: any) => {
      this.setState({
        roles,
        selectedRole: roles[0],
      })
    })
  }
}

export const AddAccountModal = connect(
  null,
  {
    push: Router.push,
  },
)(AddAccountModalComponent)
